import React from 'react';
import FirstPage from './FirstPage';
import AboutMe from './AboutMe';
import Resume from './Resume';
import Footer from './Footer';

export default function Main() {
    return (
        <div className='font-sans'>
            <section id="home" >
                <FirstPage></FirstPage>
            </section>
            <section id="about">
                <AboutMe></AboutMe>
            </section>
            <section id="resume">
                <Resume></Resume>
            </section>
            <footer id="contact">
                <Footer></Footer>
            </footer>
        </div>
    )
}