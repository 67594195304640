import React from 'react'

export default function WorkEntry({work}) {
    return (
        <div className='py-4 pl-5'>
            <div className='text-xl font-semibold'>{work.designation}</div>
            <div className='text-lg'>{work.company}</div>
            <div className='italic'>{work.date}</div>
            <p>{work.desc}</p>
        </div>
    )
}
