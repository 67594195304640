import React from 'react';
import data from '../data/data';
import { MdArrowDropDownCircle } from 'react-icons/md';
import SocialMediaBar from './SocialMediaBar';
import NavBar from './NavBar';

export default function FirstPage() {
    function goUrl(url) {
        window.location.assign(url);
    };
    return (
        <div style={{   backgroundImage: 'url("./files/images/header-background.jpg")',backgroundSize: 'cover' }}>
                <NavBar/>
                <div className="flex flex-col justify-center backdrop-blur-sm bg-white/30" style={{width: '100vw', height: '100vh' }}>
                    
                    <div className="flex items-center justify-center  font-serif">
                        <div className="backdrop-blur-sm bg-black/30 rounded w-fit p-2 drop-shadow-lg">
                            <div className="text-4xl text-center py-2 ">{data.mainHeader}</div>
                            <div className="text-lg text-center py-1"><p dangerouslySetInnerHTML={{ __html: data.mainDesc }}></p></div>
     
                        </div>
                    </div>
                    <div className="py-4 text-black/60">
                        <SocialMediaBar></SocialMediaBar>
                    </div>
                    
                    <div className="flex items-center justify-center p-2  text-black/60 hover:scale-125 ease-in duration-500">
                        <MdArrowDropDownCircle className="hover:text-slate-900" size={45} onClick={() => goUrl('#about')} ></MdArrowDropDownCircle>
                    </div>
                </div>
            </div>
    )
}
