import React from 'react';
import data from '../data/data';
import SocialMediaBar from './SocialMediaBar';

export default function Footer() {
    return (
        <div className="bg-black flex items-center justify-center text-center text-white/90">
            <div className="py-10">
                <div className='py-5'>{data.email}</div>
                <div className="text-white/60 py-2"><SocialMediaBar></SocialMediaBar></div>
                <div className=""><p>Copyright © 2023 Pasindu Ariyarathna</p></div>
            </div>
        </div>
    )
}