import React from 'react';

export default function NavBar() {
    return (
        <nav className='flex sm:justify-center space-x-4 bg-white/30 sticky top-0 z-10'>
            {[
                ['Home', '#home'],
                ['About', '#about'],
                ['Resume', '#resume'],
                ['Contact', '#contact'],
            ].map(([title, url]) => (
                <a href={url} className="rounded-lg px-3 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900">
                    {title}
                </a>
            ))}
        </nav>
        
    )
}
