import React from 'react';
import data from '../data/data';
import { FaDownload } from 'react-icons/fa';
import EducationEntry from './EducationEntry';
import WorkEntry from './WorkEntry';
import SkillLine from './SkillLine';

export default function Resume() {
    function downloadResumeHandler() {
        window.open('files/cv.pdf', '_blank');
    };
    return (
        <div className="bg-amber-100">
            <div className="container p-2">
                <div className='h3 pt-5 text-2xl font-semibold'>Work</div>
                {data.workHistory.map((work, index) => {
                    return <WorkEntry work={work} key={work.id}></WorkEntry>
                })}

                <div className='h3 pt-5 text-2xl font-semibold'>Education</div>
                {data.eduHistory.map((edu) => {
                    return <EducationEntry edu={edu} key={edu.name}></EducationEntry>
                })}

                <div className='h3 pt-5 text-2xl font-semibold max-w-fit'>Skills</div>
                {data.skills.map((skillLine, index) => {
                    return <div key={index}><SkillLine skillLine={skillLine}></SkillLine></div>
                })}
                <div onClick={downloadResumeHandler} className='py-5 flex items-center justify-center hover:text-slate-900 rounded hover:scale-125 ease-in duration-500'>
                    <FaDownload size={35}></FaDownload>
                    <p>Download Resume</p>
                </div>
            </div>
        </div>
    )
}
