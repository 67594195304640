import React from 'react';
import data from '../data/data';
import { MdEmail } from 'react-icons/md';
import { FaFacebook, FaTwitter, FaLinkedin, FaSkype, FaGithub } from 'react-icons/fa';

export default function SocialMediaBar() {
    function openUrlInNewTab(url) {
        window.open(url, '_blank');
    };
    return (
        <div className='flex items-center justify-center'>
            {data.social.facebook ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><FaFacebook size={35} onClick={() => openUrlInNewTab(data.social.facebook)}></FaFacebook></div> : null}
            {data.social.twitter ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><FaTwitter size={35} onClick={() => openUrlInNewTab(data.social.twitter)}></FaTwitter></div> : null}
            {data.social.email ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><MdEmail size={35} onClick={() => openUrlInNewTab(data.social.email)}></MdEmail> </div> : null}
            {data.social.linkedin ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><FaLinkedin size={35} onClick={() => openUrlInNewTab(data.social.linkedin)}></FaLinkedin></div> : null}
            {data.social.skype ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><FaSkype size={35} onClick={() => openUrlInNewTab(data.social.skype)}></FaSkype></div> : null}
            {data.social.github ? <div className="p-1 hover:text-slate-900 rounded hover:scale-125 ease-in duration-500"><FaGithub size={35} onClick={() => openUrlInNewTab(data.social.github)}></FaGithub></div> : null}
        </div>
    )
}