import React from 'react'

export default function EducationEntry({ edu }) {
    return (
        <div className='py-4 pl-5'>
            <div className='text-xl font-semibold'>{edu.name}</div>
            <div className='text-lg'>{edu.achivement}</div>
            <div className='italic'>{edu.year}</div>
            <p>{edu.desc}</p>
        </div>
    )
}
