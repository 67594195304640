import React from 'react';
import data from '../data/data';

export default function AboutMe() {
    return (
            <div className="bg-black flex items-center justify-center text-white/90">
                    <div className='flex-1 align-right py-20'><img className=" mx-auto rounded-full" src="/files/images/profilepic.jpg" alt="Profile"></img></div>
                    <div className='flex-1 align-left p-3'>
                        <div className="text-2xl pb-1">About Me</div>
                        <div className="pb-5">{data.aboutMeDet}</div>
                        
                        <div className="text-2xl pb-1">Contact Details</div>
                        <div className="" dangerouslySetInnerHTML={{ __html: data.contactDet }}></div>
                    </div>
            </div>
    )
}

