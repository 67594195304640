import React from 'react'

export default function SkillLine({ skillLine }) {
    return (
        <div className='py-4 pl-5 flex flex-wrap'>
            {skillLine.map((skill, index) => {
                return <span className="bg-indigo-100 text-indigo-800 rounded py-1 px-2 mr-2  border border-indigo-400 hover:text-slate-900 hover:bg-slate-100" 
                    key={skill}>{skill}</span>
            })}
        </div>
    )

}
